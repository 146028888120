/* eslint-disable no-undef */
import React, { useCallback, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import LoginForm from './LoginForm'
import { currentLocale } from 'commons/js/locale'
import Routes from 'commons/js/routes/routes'
import Keycloak from 'keycloak-js'
import { useApi } from '@elparking/utils'
import { reload } from 'commons/js/services/navigate'
import { warn } from 'commons/js/util/logger'
import loginKeycloak from 'commons/js/api/loginKeycloak'
import CountryWebloginError from './CountryWebLoginError'
import UrlParams from 'commons/js/util/UrlTo/UrlParams'
import { sendBroadcast } from 'commons/js/services/sendMessage'

const KeycloakLogin = ({
    keycloakConfig,
    onLoginSuccess,
    logo,
    loginEventName,
    trackEvent,
    simple,
    registerSuccessMessage,
    onNavigation,
    reload,
  }) => {
    const [loginUrl, setLoginUrl] = useState(null)
    const [error, setError] = useState(false)
    const [tokens, setTokens] = useState({})
    const [formHeight, setFormHeight] = useState(undefined)
    const componentId = useRef('login_' + Date.now())
    const searchParams = new UrlParams()
    searchParams.loadFromUrl()
    const {
      response: { result: user, error: loginError },
      loading,
      sendRequest: login,
    } = useApi(loginKeycloak)
    useEffect(() => {
      loginError && setError(loginError)
    }, [loginError])

  const iFrameEvent = useCallback((event) => {
      switch (event.data.eventId) {
          case 'keycloak-end-process':
            (event.data.componentId !== componentId.current) && reload(true)
            return
          case 'keycloak-token-error':
            warn(event.data.error)
            return
          case 'keycloak-error':
            setLoginUrl(null)
            return
          case 'keycloak-navigation':
              onNavigation(event.data)
              return
          case 'keycloak-token':
            const anchors = event.data.token.url.substring(event.data.token.url.indexOf('#') + 1)
            setTokens({
              ...event.data.token,
              anchors,
            })
            return
          case 'keycloak-height':
              setFormHeight(event.data.height + 10 + 'px')
              break
          case 'keycloak-close-iframe':
            reload()
            break
      }
    }, [setTokens, setFormHeight, setLoginUrl, onNavigation, reload])

    useEffect(() => {
        if (tokens.code && !error && !user) {
          login({
            code: tokens.code,
            redirectUri: Routes.keycloakLoginSuccessPath(),
          })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokens.code, error, !!user])

    useEffect(() => {
        if (user) {
          trackEvent(loginEventName)
          onLoginSuccess(user, tokens)
          sendBroadcast({ eventId: 'keycloak-end-process', componentId: componentId.current })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginEventName, onLoginSuccess, tokens.code, trackEvent, user, componentId.current])

    useEffect(() => {
      const channel = new BroadcastChannel('broadcast-channel')
      channel.addEventListener('message', iFrameEvent)
      window.addEventListener('message', iFrameEvent)
      return () => window.removeEventListener('message', iFrameEvent)
    }, [iFrameEvent])

    useEffect(() => {
      const keycloak = new Keycloak(keycloakConfig)
      !loginUrl && keycloak.init({
          redirectUri: keycloakConfig.redirect || Routes.base(),
          responseMode: keycloakConfig.responseMode,
          scope: keycloakConfig.scope,
        }).then(() => {
          const isUpdatePassword = searchParams.params && searchParams.params.update_password === 'true'
          setLoginUrl(keycloak.createLoginUrl({
            locale: currentLocale,
            redirectUri: Routes.keycloakLoginSuccessPath(),
            action: isUpdatePassword ? 'UPDATE_PASSWORD' : null,
          }))
        }).catch(() => {
          setError(true)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginUrl, keycloakConfig.redirect, keycloakConfig.responseMode, keycloakConfig.scope])

    if ((error && error.status) && error.status === 491) return <CountryWebloginError logo={logo} />

    return <LoginForm
      logo={logo}
      simple={simple}
      error={!!error}
      height={formHeight}
      authenticated={!!tokens.code}
      loading={loading}
      locale={currentLocale}
      loginUrl={loginUrl}
      registerSuccessMessage={registerSuccessMessage} />
}

KeycloakLogin.propTypes = {
  keycloakConfig: PropTypes.shape({
    redirect: PropTypes.string,
    responseMode: PropTypes.string,
    responseType: PropTypes.string,
    scope: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    url: PropTypes.string,
    realm: PropTypes.string,
    clientId: PropTypes.string,
  }),
  logo: PropTypes.node,
  onNavigation: PropTypes.func,
  onLoginSuccess: PropTypes.func,
  trackEvent: PropTypes.func,
  reload: PropTypes.func,
  loginEventName: PropTypes.string,
  registerSuccessMessage: PropTypes.bool,
  simple: PropTypes.bool,
}

KeycloakLogin.defaultProps = {
  onNavigation: () => {},
  trackEvent: () => {},
  reload,
  registerSuccessMessage: false,
  simple: false,
}

export default KeycloakLogin
